import React from 'react';

import SEO from '../components/seo'
import Layout from '../components/layout';
import { reduceGridRow } from '../utils/gridUtils';
import LinkedInIcon from '../assets/icons/linkedin.svg';
import SmallArticleCard from '../components/SmallArticleCard.jsx';
import XingIcon from '../assets/icons/xing.svg';



export default ({ pageContext }) => {
  return (
    <Layout>
      <SEO seomatic={pageContext.seomatic} />
      <div className="px-5 md:px-10">
        <div className="mx-auto max-w-screen-xxl">
          <section className="grid grid-cols-1 sm:grid-cols-2 sm:gap-6 md:gap-8 lg:grid-cols-author">
            <div className="order-2 mb-4 sm:order-1 sm:self-center">
              <h1 className="my-4 text-3xl font-light md:text-4xl xl:text-5xl">
                {pageContext.nameField}
              </h1>
              {pageContext.carlEmployee && (
                <h3 className="text-17 mb-1 md:text-xl xl:text-2xl ">
                  Carl Finance GmbH
                </h3>
              )}
              <h4 className="text-13 md:text-15">{pageContext.position}</h4>
            </div>
            <div className="order-1 mt-6 sm:order-2">
              <img
                src={pageContext.avatar[0]?.url}
                alt={pageContext.nameField}
                className="w-full sm:max-w-lg"
                loading="lazy"
              />
            </div>
            <div className="order-4 mb-10 sm:order-3 md:mb-15 lg:mb-24">
              <p className="text-15 md:text-base lg:w-11/12 xl:w-10/12">
                {pageContext.excerpt}
              </p>
            </div>
            <div className="w-full overflow-hidden break-words flex flex-col order-3 text-13 sm:order-4 md:text-15 lg:flex-row">
              <table className="mb-5 md:mr-8">
                <tbody>
                {pageContext.phone && (
                    <tr>
                      <td>Tel.</td>
                      <td>
                        <a href={`tel:+${pageContext.phone}`} className="link">
                          {pageContext.phone}
                        </a>
                      </td>
                    </tr>
                  )}
                  {pageContext.email && (
                    <tr>
                      <td className="pr-4 pb-6 whitespace-nowrap align-text-top">E-Mail</td>
                      <td className="align-text-top">
                        <a href={`mailto:${pageContext.email}`} className="link">
                          {pageContext.email}
                        </a>
                      </td>
                    </tr>
                  )}
                  {pageContext.linkedinLink && (
                    <tr>
                      <td>
                        <LinkedInIcon className="w-6 h-6 sm:w-8 sm:h-8" />
                      </td>
                      <td>
                        <a
                          href={pageContext.linkedinLink}
                          className="flex items-center link"
                        >
                          LinkedIn
                        </a>
                      </td>
                    </tr>
                  )}
                  {pageContext.xingLink && (
                    <tr>
                      <td className="pt-1">
                        <XingIcon className="w-6 h-6 sm:w-8 sm:h-8" />
                      </td>
                      <td className="pt-1">
                        <a
                          href={pageContext.xingLink}
                          className="flex items-center link"
                        >
                          Xing
                        </a>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <ul className="mb-8 lg:w-5/12">
                {pageContext.previousPositions[0].previousPosition1 && (
                  <li className="mb-2">
                    {pageContext.previousPositions[0].previousPosition1}
                  </li>
                )}
                {pageContext.previousPositions[0].previousPosition2 && (
                  <li className="mb-2">
                    {pageContext.previousPositions[0].previousPosition2}
                  </li>
                )}
                {pageContext.previousPositions[0].previousPosition3 && (
                  <li className="mb-2">
                    {pageContext.previousPositions[0].previousPosition3}
                  </li>
                )}
                {pageContext.previousPositions[0].previousPosition4 && (
                  <li className="mb-2">
                    {pageContext.previousPositions[0].previousPosition4}
                  </li>
                )}
                <li className="mb-2">{pageContext.school}</li>
              </ul>
            </div>
          </section>
          <section>
            <hr className="h-px border-none bg-black-700.5" />
            <h5 className="font-bold text-15 mb-2 mt-8 md:text-base md:mb-6 md:mt-12 lg:mt-16 lg:mb-8 lg:text-17">
              Artikel von {pageContext.nameField}
            </h5>
            <div className="grid grid-cols-1 mb-2 max-w-screen-xxl sm:grid-cols-2 sm:gap-4 sm:row-gap-8 sm:mb-4 md:mb-10 lg:grid-cols-4 lg:mb-15">
              {reduceGridRow(pageContext.articles).map(article => (
                <SmallArticleCard
                  author={article.articleAuthor[0].title}
                  category={article.category[0].title}
                  date={article.lastRevision ? article.lastRevision : article.postDate}
                  excerpt={article.excerpt}
                  thumbnail={article.thumbnailLarge[0]?.url}
                  title={article.title}
                  url={article.uri}
                  key={article.id}
                />
              ))}
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};
